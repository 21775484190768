<template>
  <div>
   <base-header class="pb-1">
       <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>

   <div class="container-fluid mt--6">
        <card>
            <h3 slot="header" class="mb-0">Relatório de Inadimplência - Demais</h3>
            <div class="row mb-4">
                <div class="col-md-3">
                    <label>Dias de Atraso</label>
                    <input type="text" class="form-control" v-model="reportFilter.days" 
                        placeholder="Dias de atraso" @keypress.enter="loadReport" maxlength="3"/>           
                </div>    
                <div class="col-md-9 text-right mt-4">
                    <base-button type="primary" native-type="button" @click="exportReport">
                        <i class="far fa-file-excel mr-1"></i>Exportar
                    </base-button>
                </div>
            </div>

            <table class="table table-responsive font-tabela-peq">
                <thead class="thead">
                    <tr>
                        <th scope="col">Assinatura</th>
                        <th scope="col">Titular</th>
                        <th scope="col">Tel. Celular</th>
                        <th scope="col">Tel. Residencial</th>
                        <th scope="col">E-mail</th>
                        <th scope="col">Forma de Pagamento</th>
                        <th scope="col">Cartão Informado</th>
                        <th scope="col">Faturas em Aberto</th>
                        <th scope="col">Valor em Aberto</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="report in reportResponse.details" :key="report.subscriptionId">
                        <td>{{report.subscriptionId}}</td>
                        <td>{{report.holderName}}</td>
                        <td>{{report.cellphone}}</td>
                        <td>{{report.homephone}}</td>
                        <td>{{report.mail}}</td>
                        <td>{{report.paymentType}}</td>
                        <td>{{report.noCard}}</td>
                        <td>{{report.quantityInvoices}} fatura<span v-show="report.quantityInvoices > 1">s</span></td>
                        <td>{{report.amountDebtFmt}}</td>
                        <td>
                            <a href="#" title="Assinatura" @click.prevent="open(report.subscriptionId)"><i class="far fa-folder-open"></i></a>
                            <a href="#" title="Registrar Contato (CallCenter)" @click.prevent="newContact(report.holderId)"><i class="fas fa-headset ml-2"></i></a>
                        </td>
                    </tr>
                    <tr v-show="reportResponse.details.length == 0">
                        <td colspan="4">
                        <center> Nenhuma informação disponível no relatório.</center>
                        </td>
                    </tr>
                </tbody>
            </table>

        </card> 

        <div class="row">
                <div class="col-md-12" v-if="reportResponse.total != null">
                <card>
                    <h4>Totalização Geral</h4>
                    <p v-for="totalDetail in reportResponse.total" :key="totalDetail.paymentType" class="text-nowrap font-tabela-peq">
                        <span v-if="totalDetail.paymentType == 'DEBIT_CARD'">
                            <i class="fas fa-credit-card mr-2"></i> 
                            Cartão de Débito : <strong>{{totalDetail.totalFmt}}</strong>
                        </span> 
                        <span v-if="totalDetail.paymentType == 'CREDIT_CARD'">
                            <i class="far fa-credit-card mr-2"></i> 
                            Cartão de Crédito : <strong>{{totalDetail.totalFmt}}</strong>
                        </span>
                        <span v-if="totalDetail.paymentType == 'TICKET'">
                            <i class="fas fa-money-check-alt mr-2"></i> 
                            Boleto : <strong>{{totalDetail.totalFmt}}</strong>
                        </span>
                        <span v-if="totalDetail.paymentType == 'TICKETS'">
                            <i class="fas fa-money-check mr-2"></i> 
                            Boleto/Carnê: <strong>{{totalDetail.totalFmt}}</strong>
                        </span>   
                    </p>
                    <hr>
                    <p class="text-nowrap font-tabela-peq"><span class="mr-2">=</span> <i class="fas fa-dollar-sign mr-2"></i> Total Geral : <strong>{{reportResponse.totalGeneralFmt}}</strong></p>
                </card>    
            </div>  
        </div>
    </div> <!--- fecha conteudo -->

    <modal :show.sync="showModalContact">
        <h4 class="text-center">Callcenter - Registro de Ligação</h4>
        <div class="col-md-4 col-md-8 font-pequena">
            <span>Titular:</span> <span class="ml-1">{{holderData.name}}</span>
        </div>  
        <div class="col-md-12 col-md-12 font-pequena">
            <span>Tel Residencial:</span> <span class="ml-1">{{holderData.homePhone}}</span>
            <span class="ml-3">Tel Celular:</span> <span class="ml-1">{{holderData.cellPhone}}</span>
        </div> 
        <div class="col-md-2 col-md-10 font-pequena">
            <span>E-mail:</span> <span class="ml-1">{{holderData.email}}</span>
        </div> 
        <div class="col-md-12 mt-2 text-center">
            <textarea rows="5" v-model="contactForm.description" style="width:100%;" class="form-control" placeholder="Registre as informações do contato com cliente"></textarea><br>
            <h4 v-if="protocolo != null"> Protocolo: {{protocolo}}</h4><br>
            <base-button type="primary" :disabled="beginSaveProcess" @click="saveContact">Registrar Contato</base-button>
        </div>
        <div class="col-md-12 text-center mt-4 font-pequena" v-if="contactHolderList.length == 0"><strong>Não existem registros de ligações para esse titular.</strong></div>
        <div class="row font-pequena scroll-ligacoes mt-2" v-if="contactHolderList.length > 0">
            <div class="col-md-12 font-pequena" v-for="contact in contactHolderList" :key="contact.id">
                <hr>
                <span class="font-pequena">Protocolo: <strong>{{contact.id}}</strong></span><br>
                <span class="font-pequena">Data/Hora: <strong>{{contact.dateTimeContact}}</strong></span><br>
                <span class="font-pequena">Operador: <strong>{{contact.user.name}}</strong></span><br>
                <span class="font-pequena">Registro: <strong>{{contact.description}}</strong> </span>
            </div> 
        </div>     
            
    </modal>      
  </div>
</template>
<script>
import FileSaver from 'file-saver'
import { Select, Option } from 'element-ui'
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
        [Select.name]: Select,
        [Option.name]: Option,
  },
  mounted(){
      this.loadReport()
  },
  data() {
    return {
        reportFilter:{
            days: 30,
            ativas: false,
            dateBegin: null,
            dateEnd: null,
            codes: []
        },
        protocolo : null,
        beginSaveProcess: false,
        showModalContact : false,
        contactForm: {
            description: null,
            holder : {
                id : null
            }
        },
        reportResponse : {
            details : [],
            total: []
        },
        contactHolderList: [],
        holderData: {
            name: null,
            email: null,
            homePhone: null,
            cellPhone: null
        }
    }
  },
  methods: {
      newContact(holderId){
        this.protocolo = null
        this.contactForm.description = null
        this.$clubApi.get('/holder/'+holderId)
        .then((response) => {
           this.holderData = response.data.object
           this.contactForm.holder.id = holderId
        }) .catch((error) => {
           this.$notify({type: 'warning', message: error.response.data.msg})
        }).finally(() =>{
            NProgress.done() 
        })

        //obtendo ultimas ligacoes para o titular
        this.$clubApi.get('/contact-holder/holder/'+holderId)
        .then((response) => {
            this.contactHolderList = response.data.object
        }) .catch((error) => {
            this.$notify({type: 'warning', message: error.response.data.msg})
        }).finally(() =>{
            NProgress.done() 
        })
        this.showModalContact = true
      },
      saveContact(){
          this.beginSaveProcess = true
          this.$clubApi.put('/contact-holder', this.contactForm)
            .then((response) => {
                this.newContact(response.data.object.holder.id)//reload 
                this.$notify({type: 'success', message: 'Registro efetuado!'})
                this.protocolo = response.data.object.id
            }) .catch((error) => {
                this.$notify({type: 'warning', message: error.response.data.msg})
            }).finally(() =>{
                this.beginSaveProcess = false
                NProgress.done() 
            })
      },
      open(subscriptionId){
          location.href = '/assinatura/'+subscriptionId
      },
      loadReport() {
        this.reportResponse = {
            details : [],
            total: []
        },
        this.$clubApi.post('/report/debtOthers', this.reportFilter)
        .then((response) => {
            this.reportResponse = response.data.object
        }) .catch((error) => {
           this.$notify({type: 'warning', message: error.response.data.msg})
        }).finally(() =>{
            NProgress.done() 
        })
      },
      exportReport(){
        this.$clubApi.post('/report/debtOthers/export', this.reportFilter, {
            responseType : 'blob'
        }).then((response) => {
            var blob = new Blob([response.data], {
                type: 'application/vnd.ms-excel'
            });
            FileSaver.saveAs(blob, 'relatorio-inadimplentes.xlsx');
        }) .catch((error) => {
            this.$notify({type: 'warning', message: error.response.data.msg})
        }).finally(() =>{
            NProgress.done() 
        })
      }
  }
};
</script>

<style>
.font-pequena{
    font-size: 10px;
}
.scroll-ligacoes{
    overflow-y: scroll;
    overflow-x: none;
    height: 300px;
}

.vue-tablist {
  list-style: none;
  display: flex;
  padding-left: 0;
  border-bottom: 1px solid #e2e2e2;
}

.vue-tab {
  padding: 5px 10px;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  border-bottom-color: #e2e2e2;
  border-radius: 3px 3px 0 0;
  background-color: white;
  position: relative;
  bottom: -1px;
  font-size: 13px;
}

.vue-tab[aria-selected="true"] {
  border-color: #e2e2e2;
  border-bottom-color: transparent;
  background-color: #f8f8ff;
}

.vue-tab[aria-disabled="true"] {
  cursor: not-allowed;
  color: #999;
}
</style>